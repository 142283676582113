<style lang="sass" scoped>

</style>
<template lang="pug">
.bg-white.shadow-sm.p-4.mt-4.ml-0(style='max-width: 780px')
  h4.border-bottom.py-4.pl-2(style='border-top: solid 1px #333') 가져오기
  .border-bottom
    .p-4
      strong.mb-2.d-block 엑셀/CSV를 업로드하여 대량으로 고객정보를 추가합니다.
      //- pre {{headers}}
      br
    .border.p-4
      h6 1. 양식 다운로드
      a.text-primary(href='#' @click.prevent.stop='download_import') Click to download: cream-import.xlsx
      br
    .border.p-4.mt-2
      h6.mb-4 2. 내용 업로드
      textarea.form-control(v-model='import_data' rows=5)
      button.btn.btn-light.border(type='button' @click='preview') 미리보기

    .border.mt-2
      .p-4
        h6 3. 미리보기
        //- pre {{results.data}}
        .text-center
          strong.mr-4.text-primary 성공: {{results.data.length}}건
          strong.mr-4.text-muted 오류: {{results.errors.length}}건
        pre(v-show='results.errors.length') {{results.errors}}
        //- pre {{results.meta}}

      table.table
        thead
          tr.bg-light
            th(v-for='h in headers') {{h}}
        tbody
          tr(v-for='d in results.data')
            td(v-for='datum in d') {{datum}}

    .border.p-4.mt-2
      h6.mb-4 4. 완료
      button.btn.btn-primary.btn-lg(type='button' @click='submit') {{results.data.length}}건 일괄 추가



</template>

<script>

const xlsx = require('xlsx')
const Papa = require('papaparse')

export default {
  name: 'index',
  props: ['property_id'],
  components: {

  },
  computed: {
    // session() {
    //   return this.$store.state.session
    // },
  },
  mounted() {
    // this.load()
    this.preview()
  },
  data() {
    return {
      loading: false,
      headers: ['고객명','휴대폰','이메일'],
      results: {
        data: [],
        errors: [],
        meta: {},
      },
      import_data: `테스트1	010-4102-9921	jinhyuk.lee@studiointo.asia
테스트2	010-4102-9921	jinhyuk.lee@studiointo.asia
테스트3	010-4102-9921	jinhyuk.lee@studiointo.asia`,
    }
  },
  methods: {
    download_import() {
      console.log('>>')
      const wb = xlsx.utils.book_new()
      const ws = xlsx.utils.json_to_sheet([
        {'고객명': '', '휴대폰': '', '이메일': ''},
        {'고객명': '', '휴대폰': '', '이메일': ''},
        {'고객명': '', '휴대폰': '', '이메일': ''},
      ], {header:['고객명','휴대폰','이메일'], skipHeader:false});
      xlsx.utils.book_append_sheet(wb, ws, '엑셀입력양식')
      xlsx.writeFile(wb, `cream-import-${Date.now()}.xlsx`);
    },
    preview() {
      const r = Papa.parse(this.import_data)
      this.results = r
      // console.log(r)
    },
    async submit() {
      try {
        for(const row of this.results.data) {
          const r = await this.$http.post(`/v1/property/${this.property_id}/orders`, {
            name: row[0],
            phone: row[1],
            email: row[2],
          })
          if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '오류')

        }
        this.$modal.show('dialog', {
          title: '알림',
          text: '추가했습니다.',
        })

      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    }
  },
}
</script>
